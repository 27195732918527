<template>
  <b-row>
    <b-col cols="12">
      <div v-if="loading">
        <b-button class="mb-3" :to="{name: 'reports'}" variant="primary"><fa-icon class="mr-1" icon="arrow-left" /> Reports</b-button>
        <div class="text-center">
          <b-spinner variant="primary"></b-spinner>
          <p class="mt-2 h6">Loading...</p>
        </div>
      </div>
      <div v-if="!loading && values">
        <div class="d-flex flex-row mb-3">
          <b-button class="mr-1" :to="{name: 'reports'}" variant="primary"><fa-icon class="mr-1" icon="arrow-left" /> Reports</b-button>
          <b-button class="mr-1" :href="csvLink" variant="light"><fa-icon class="mr-1" icon="download" /> CSV</b-button>
          <div class="justify-content-end d-flex flex-row flex-fill">
            <b-input-group style="max-width: 700px;">
              <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'long', day: undefined, weekday: undefined }" v-model="date" />
            </b-input-group>
          </div>
        </div>
        <b-table bordered responsive striped head-variant="dark" :fields="fields" :items="values">
          <template #cell(name)="{ item }">
            {{ item.name }} (Issued {{ moment(item.issued_at).format('MMMM YYYY') }})
          </template>
          <template #cell(inflow_outstanding)="{ item }">
            {{ item.inflow_outstanding ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(item.inflow_outstanding)) : 'N/A' }}
          </template>
          <template #cell(inflow)="{ item }">
            {{ item.inflow ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(item.inflow)) : 'N/A' }}
          </template>
          <template #cell(outflow)="{ item }">
            {{ item.outflow ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(item.outflow)) : 'N/A' }}
          </template>
        </b-table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      date: this.moment().add(-1, 'month').toDate(),
      loading: false,
      values: {},
      fields: [
        { key: 'name', text: 'Statement' },
        { key: 'inflow_outstanding', text: 'Outstanding Inflow' },
        { key: 'inflow', text: 'Paid Inflow' },
        { key: 'outflow', text: 'Outflow' },
        { key: 'payable_to', text: 'Payable To' },
      ],
    };
  },
  computed: {
    csvLink() {
      return `/api/reports/inoutflow.csv?date=${this.moment(this.date).startOf('month').format('YYYY-MM-DD')}`;
    }
  },
  async mounted() {
    // Load Report
    await this.load();
  },
  methods: {
    moment,
    async load() {
      this.loading = true;

      const response = await window.apiFetch(`/reports/inoutflow?date=${this.moment(this.date).startOf('month').format('YYYY-MM-DD')}`);
      this.values = await response.json();

      this.loading = false;
    }
  }
}
</script>
